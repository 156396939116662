import React, {useContext, useEffect, useState} from "react";
import {Dropdown, DropdownAnchor, DropdownItem, DropdownList} from "../Components/Dropdown";
import {SimpleModal, useSimpleModalState}   from "../Components/SimpleModal";
import {useTranslation}                     from "react-i18next";
import Auth                                 from "../Requests/Auth";
import {LogOut, User}                       from "../Requests/User";
import {Link, useNavigate} from "react-router-dom";
import {UserContext} from "../Contexts/UserContext";
import {GET} from "../Hooks/RequestV2";
import {CartContext} from "../Contexts/CartContext";
import {ForceReloadContext} from "../Contexts/ForceReloadContext";
import {ChannelContext} from "../Contexts/ChannelContext";
import {PageLogin} from "../Pages/LogIn/Form";

/**
 * Login form as MODAL with open modal button
 */
function LoginForm(){
    const {visibility, toggleVisibleModal, setVisibility}   = useSimpleModalState();
    const {response, setBody, httpStatusCode}               = Auth();
    const {loggedUser, sendRequestForUser, userHttpStatusCode} = User();
    const {setUser, user}                                   = useContext(UserContext);
    const {setCart}                                         = useContext(CartContext);
    const forceReloadContext                                = useContext(ForceReloadContext);
    const channelContext                                    = useContext(ChannelContext);
    const [status, setStatus]                               = useState(user ? 'logged' : null);

    /** if user is logged, send request for user data **/
    useEffect(()=>{
        if (httpStatusCode === 401){
            setStatus('not_auth');
        } else if (httpStatusCode === 204) {
            forceReloadContext.setForceReloadView('login');
            setStatus('auth');
            sendRequestForUser(true);
        } else if (httpStatusCode === 404){
            setStatus('error');
        }
    }, [httpStatusCode]);

    /** If we get user data, disable modal and add user dropdown menu **/
    useEffect(()=>{
        if (loggedUser !== null){
            setTimeout(() => toggleVisibleModal(), 1000);
            setTimeout(() => {

                if (userHttpStatusCode === 301) {
                    window.location.href = 'https://'+loggedUser.host+'/?redirect='+channelContext.name;
                } else {
                    setUser(loggedUser);
                    setStatus('logged');

                    GET('/user/cart')
                        .then((response) => response.json())
                        .then((json) => setCart(json));
                }
            }, 2000);
        }
    }, [loggedUser]);

    /** Set modal visibility to null (because is hidden default and after logout it visibility on one second) **/
    useEffect(()=>{
        if (user === null){
            setVisibility(null);
        }
    }, [user]);


    if (user === false){
        return(
            <UserLogin {...{toggleVisibleModal, visibility, status, setBody, user}} />
        );
    } else {
        return(
            <UserMenu username={user.username} setVisibility={setVisibility} />
        );
    }
};

/**
 * Check auth response and visible notification about verification or not
 */
function AuthStatusAfterFormSend(props){
    const {t} = useTranslation();

    if (props.status === 'error'){
        return (
            <div className="form-error" >
                <i className="fas fa-times-circle"></i>
                <span>{t('layout.login-form.error')}</span>
            </div>
        );
    } else if (props.status === 'auth'){
        return (
            <div className="form-success" >
                <i className="fas fa-check-circle"></i>
                <span>{t('layout.login-form.success')}</span>
            </div>
        );
    } else if (props.status === 'not_auth') {
        return (
            <div className="form-error" >
                <i className="fas fa-times-circle"></i>
                <span>{t('layout.login-form.not-auth')}</span>
            </div>
        );
    } else {
        return (<></>);
    }
}

/**
 * Login form
 */
function UserLogin({toggleVisibleModal, visibility, status, setBody, user}){
    const {t} = useTranslation();

    return(
        <>
            <button className="btn" id={'login-button'} onClick={toggleVisibleModal} >{t('layout.header.login')}</button>
            <SimpleModal visibility={visibility} hidden={toggleVisibleModal}  >
                <div className={'login-wrapper'} >
                    <PageLogin />
                </div>
            </SimpleModal>
        </>
    );
}

/**
 * Logged user menu
 */
function UserMenu(props){
    const {t}                   = useTranslation();
    const {response, setSend}   = LogOut();
    const {setUser}             = useContext(UserContext);
    const channelContext        = useContext(ChannelContext);

    useEffect(()=>{
        if (response !== null && response.logout === true){
            window.location.href = '/';
            localStorage.removeItem('contractor');
            // setUser(false);
            props.setVisibility(null);
        }
    }, [response]);

    return(
        <Dropdown className="user-menu" >
            {/*<DropdownAnchor>{t('layout.user-menu.logged')} <span>{props.username}</span></DropdownAnchor>*/}
            <div className="dropdown-anchor" >
                <button>
                    <span>
                        <span><i className="fas fa-user" ></i> {t('layout.user-menu.my-account')} <i className={'fas fa-angle-right'} ></i></span>
                        <span className={'username'} >{t('layout.user-menu.logged')} <strong>{props.username}</strong></span>
                    </span>
                </button>
            </div>
            <DropdownList>
                <DropdownItem>
                    <Link to={'/user/my-account'} >
                        <i className="fas fa-cog" ></i> {t('layout.user-menu.config')}
                    </Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to={'/user/orders'} >
                        <i className="fas fa-dolly" ></i> {t('layout.user-menu.orders')}
                    </Link>
                </DropdownItem>
                {channelContext?.isExportDepartment === false ?

                    <>
                        <DropdownItem>
                            <Link to={'/user/invoices'} >
                                <i className="fas fa-file-invoice" ></i> {t('layout.user-menu.invoices')}
                            </Link>
                        </DropdownItem>

                        {/*<DropdownItem>*/}
                        {/*    <Link to={'/user/wz'} >*/}
                        {/*        <i className="fas fa-pallet" ></i> {t('layout.user-menu.wz')}*/}
                        {/*    </Link>*/}
                        {/*</DropdownItem>*/}
                    </>

                    : null}

                <DropdownItem>
                    <Link to={'/user/saved-carts'} >
                        <i className="fas fa-cart-arrow-down" ></i> {t('layout.user-menu.saved-cart')}
                    </Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to={'/user/load-order'} >
                        <i className="fas fa-file-upload"></i> {t('layout.user-menu.load-order')}
                    </Link>
                </DropdownItem>
                {channelContext?.isExportDepartment === false ?
                    <DropdownItem>
                        <Link to={'/user/payments'} >
                            <i className="fas fa-credit-card" ></i> {t('layout.user-menu.payments')}
                        </Link>
                    </DropdownItem>
                : null }
                <DropdownItem clickEvent={setSend} >
                    <a href={'javascript:void(0);'} ><i className="fas fa-lock" ></i> {t('layout.user-menu.logout')}</a>
                </DropdownItem>
            </DropdownList>
        </Dropdown>
    );
}

export {LoginForm, AuthStatusAfterFormSend};
