import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {headerScrollVisibleToggle} from "../Layouts/Header/HeaderScroll";

/**
 * All logic for hover menu in tree
 */
function useMenu(menuButtonRef, {
    enableTimeout, setEnableTimeout,
    enableHover, setEnableHover,
    menu, setMenu,
    subMenu1, setSubMenu1,
    subMenu2, setSubMenu2 }){

    const [visibleScrollMenu, setVisibleScrollMenu] = useState(false);
    const baseMenuEl = visibleScrollMenu === true ? document.querySelector('.header-scroll menu') : document.querySelector('header menu');

    headerScrollVisibleToggle(setVisibleScrollMenu, 25);

    useEffect(() => {
        const scrollAllMenuItems = document.querySelectorAll('.header-scroll menu .container .menuitem');
        const headerAllMenuItems = document.querySelectorAll('header menu .container .menuitem');

        if (scrollAllMenuItems){
            for (let x = 0; x < scrollAllMenuItems.length; x++){

                if (visibleScrollMenu === false){
                    scrollAllMenuItems[x].className = 'menuitem';
                }
            }
        }

        if (headerAllMenuItems){
            for (let x = 0; x < headerAllMenuItems.length; x++){

                if (visibleScrollMenu === true){
                    headerAllMenuItems[x].className = 'menuitem';
                }
            }
        }

    }, [visibleScrollMenu]);

    /**
     * Get id, positions, DOM and other data of choice sub menu
     */
    function getMenuItemData(item){
        const menuButton        = menuButtonRef.current;
        const topMenuPosition   = baseMenuEl.getBoundingClientRect().top;
        const submenuId         = parseInt(item.getAttribute('data-id'));
        const submenu           = menuButton.querySelector('.sm[data-id="'+submenuId+'"]');
        const topElPosition     = item.getBoundingClientRect().top;
        const widthEl           = item.parentNode.getBoundingClientRect().width;

        return {topMenuPosition, submenuId, submenu, topElPosition, widthEl, menuButton};
    }

    /**
     * Set style position for choice sub menu
     */
    function setStyleSubMenu({submenu, left, top, marginLeft}){
        submenu.style.left          = left;
        submenu.style.top           = top;
        submenu.style.marginLeft    = marginLeft;
        submenu.style.visibility    = 'visible';
        submenu.style.opacity       = '1';
    }

    /**
     * Set null to all states when pointer leave menu
     */
    function resetStates(){
        setMenu(null);
        setSubMenu1(null);
        setSubMenu2(null);
        clearTimeout(enableTimeout);
        setEnableTimeout(null);
        setEnableHover(false);
    }

    /**
     * Set (when component build) default left position for submenu lvl 2
     */
    function marginThreeLvlTree(){
        if (!menuButtonRef.current){
            return;
        }

        const items     = menuButtonRef.current.querySelectorAll('.submenu-list2');
        const position  = menuButtonRef.current.querySelector('.menu-list').getBoundingClientRect().width + 50;

        for (let x = 0; x < items.length; x++){
            // items[x].style.left = position+'px';
        }
    }

    /**
     * Hover first lvl sub menu
     */
    function  hoverSubMenu1(e){
        const {topMenuPosition, submenuId, submenu, topElPosition, widthEl, menuButton} = getMenuItemData(e.currentTarget);

        if (submenuId !== subMenu1 && subMenu1 !== null){
            menuButton.querySelector('.sm[data-id="'+subMenu1+'"]').removeAttribute('style');

            if (subMenu2 !== null){
                menuButton.querySelector('.sm[data-id="'+subMenu2+'"]').removeAttribute('style');

                setSubMenu2(null);
            }
        }

        if (enableHover === true){
            const issetScrollableMenu = menuButton.parentNode.parentNode.parentNode.classList.contains('header-scroll');

            let pos;

            submenu.querySelector('.container').scrollTop = 0;

            const paddingTop = parseInt(window.getComputedStyle(submenu).getPropertyValue('padding-top').replace('px', ''));
            const headerHeight = document.querySelector('#root > header').offsetHeight;
            const scrollTop = window.scrollY;

            const realHeightOfSubmenuContainer = (submenu.offsetHeight - (2 * paddingTop));
            let placeForMenu;

            if (issetScrollableMenu === false){
                placeForMenu = window.innerHeight - ((window.innerHeight - scrollTop) - headerHeight);
            } else {
                const menuScrollOffsetHeight = document.querySelector('.header-scroll').offsetHeight;
                placeForMenu = window.innerHeight - menuScrollOffsetHeight;
            }


            if (placeForMenu <= realHeightOfSubmenuContainer){

                if (submenu.querySelector('.container').style.maxHeight === ''){
                    // submenu.querySelector('.container').style.maxHeight = (placeForMenu - 10)+'px';
                }

                // pos = 50;
                pos = (topElPosition - topMenuPosition);
            } else {
                pos = issetScrollableMenu === false ? (topElPosition - topMenuPosition) : topElPosition;

                if ((placeForMenu - topElPosition - 5) < 250){

                    let maxHeight = (window.innerHeight - topElPosition - 5);

                    if (realHeightOfSubmenuContainer > maxHeight){
                        const upper = realHeightOfSubmenuContainer - maxHeight;

                        pos -= upper - 8;
                        maxHeight += upper;
                    }

                    if (submenu.querySelector('.container').style.maxHeight === ''){
                        // submenu.querySelector('.container').style.maxHeight = (maxHeight - 10)+'px';
                    }
                } else {
                    // let menuScrollOffsetHeight = 0;

                    if (issetScrollableMenu !== null){
                        // menuScrollOffsetHeight = document.querySelector('.header-scroll').offsetHeight;
                        const sh = submenu.offsetHeight;
                        const scrollPlace = (window.innerHeight - headerHeight) + 10;

                        // pos = scrollPlace - pos - ;
                    }
                }
            }

            const listWidth = menuButtonRef.current.querySelector('.menu-list').offsetWidth;
            const buttonLeftPosition = menuButtonRef.current.getBoundingClientRect().left;
            let left;


            const paddingLeft = parseInt(window.getComputedStyle(submenu).getPropertyValue('padding-left').replace('px', ''));
            const leftButtonPosition = menuButton.getBoundingClientRect().left;

            const leftMenuContainerPosition = menuButton.querySelector('.menu-list .container').getBoundingClientRect().left;
            const widthMenuContainer = menuButton.querySelector('.menu-list .container').offsetWidth;

            const leftSubContainerPosition = submenu.querySelector('.container').getBoundingClientRect().left;

            if (((listWidth / 2) - paddingLeft) > buttonLeftPosition){
                left = (widthEl - paddingLeft)+9+'px';
            } else {
                left = 'calc(50% + '+((widthEl / 2) + 9)+'px)';
            }


            if (leftMenuContainerPosition === 16){
                left = widthMenuContainer - paddingLeft;
                console.debug('A')
            } else {
                if (leftButtonPosition === leftSubContainerPosition){
                    if (window.innerWidth > 1200){
                        if (issetScrollableMenu){
                            console.debug('B')
                            // left = (widthMenuContainer - paddingLeft) + 8;
                            left = (widthMenuContainer - (leftSubContainerPosition - leftMenuContainerPosition));
                        } else {
                            console.debug('C')
                            if (Array.from(submenu.parentNode.parentNode.children).indexOf(submenu.parentNode) === 0){
                                // left = (widthMenuContainer - paddingLeft) + 10;
                                const containerWidth = menuButtonRef.current.querySelector('.menu-list .container').offsetWidth;
                                const widthMenuButton = menuButtonRef.current.offsetWidth;
                                const least = (containerWidth - widthMenuButton) / 2;
                                const buttonLeftPosition = menuButtonRef.current.getBoundingClientRect().left;

                                // console.debug(submenu.parentNode)

                                if (least > buttonLeftPosition){
                                    left = (widthMenuContainer - ((leftSubContainerPosition - leftMenuContainerPosition) + paddingLeft));
                                    console.debug('CC')
                                } else {
                                    console.debug('CD')
                                    left = (widthMenuContainer - paddingLeft) + 20;
                                }
                            } else {
                                left = (widthMenuContainer - (leftSubContainerPosition - leftMenuContainerPosition));
                                console.debug('CX')
                            }
                        }
                    } else  {
                        console.debug('D')
                        if (Array.from(submenu.parentNode.parentNode.children).indexOf(submenu.parentNode) === 0){
                            if (issetScrollableMenu){
                                left = (widthMenuContainer - ((leftSubContainerPosition - leftMenuContainerPosition) ));
                                console.debug('D3')
                            } else {
                                left = (widthMenuContainer - ((leftSubContainerPosition - leftMenuContainerPosition) + paddingLeft));
                                console.debug('D4')
                            }

                            console.debug('D1')
                        } else {
                            console.debug('D2')
                            left = (widthMenuContainer - (leftSubContainerPosition - leftMenuContainerPosition));
                        }
                    }
                }
            }

            if (issetScrollableMenu){
                submenu.querySelector('.container').style.height = 'calc(100vh - '+(document.querySelector('.header-scroll').offsetHeight + 38)+'px'+')';
                submenu.querySelector('.arrow').style.height = 'calc(100vh - '+(document.querySelector('.header-scroll').offsetHeight + 38)+'px'+')';
                submenu.style.height = 'calc(100vh - '+(document.querySelector('.header-scroll').offsetHeight + 38)+'px'+')';

                setStyleSubMenu({
                    submenu: submenu,
                    marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
                    left: (left+10)+'px',
                    top: '68px' });
            } else {
                submenu.querySelector('.container').style.height = 'calc(100vh - '+(document.querySelector('#root > header').offsetHeight + 38 )+'px'+')';
                submenu.querySelector('.arrow').style.height = 'calc(100vh - '+(document.querySelector('#root > header').offsetHeight + 38 )+'px'+')';
                submenu.style.height = 'calc(100vh - '+(document.querySelector('#root > header').offsetHeight + 38)+'px'+')';

                setStyleSubMenu({
                    submenu: submenu,
                    marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
                    left: (left+10)+'px',
                    top: '52px' });
            }

            setSubMenu1(submenuId);
        }
    }

    /**
     * Hover second lvl sub menu
     */
    function  hoverSubMenu2(e){
        const {topMenuPosition, submenuId, submenu, topElPosition, widthEl, menuButton} = getMenuItemData(e.currentTarget);

        const subMenu1      = menuButton.querySelector('.item[data-id="'+submenuId+'"]').parentNode.parentNode;
        const startPosition = menuButtonRef.current.getBoundingClientRect().width + 50;

        if (submenuId !== subMenu2 && subMenu2 !== null){
            menuButton.querySelector('.sm[data-id="'+subMenu2+'"]').removeAttribute('style');
            menuButton.querySelector('.sm[data-id="'+subMenu2+'"]').style.left = startPosition+'px';
        }

        submenu.querySelector('.container').scrollTop = 0;

        const issetScrollableMenu = menuButton.parentNode.parentNode.parentNode.classList.contains('header-scroll');

        let pos;

        const headerHeight = document.querySelector('#root > header').offsetHeight;
        const scrollTop = window.scrollY;
        const paddingTop = parseInt(window.getComputedStyle(submenu).getPropertyValue('padding-top').replace('px', ''));
        // const placeForMenu = (window.innerHeight - headerHeight - paddingTop) + scrollTop;

        const realHeightOfSubmenuContainer = (submenu.offsetHeight - (2 * paddingTop));
        let placeForMenu;

        if (issetScrollableMenu === false){
            placeForMenu = window.innerHeight - ((window.innerHeight - scrollTop) - headerHeight);
        } else {
            const menuScrollOffsetHeight = document.querySelector('.header-scroll').offsetHeight;
            placeForMenu = window.innerHeight - menuScrollOffsetHeight;
        }

        // const placeForMenu = window.innerHeight - ((window.innerHeight - scrollTop) - headerHeight);

        if (placeForMenu <= realHeightOfSubmenuContainer){
            // if (submenu.querySelector('.container').style.maxHeight === ''){
            //     submenu.querySelector('.container').style.maxHeight = (placeForMenu - 10)+'px';
            // }


            pos = (topElPosition - topMenuPosition);
        } else {
            pos = issetScrollableMenu === false ? (topElPosition - topMenuPosition) : topElPosition;
            if ((placeForMenu - topElPosition - 5) < 250){
                let maxHeight = (window.innerHeight - topElPosition - 5);

                if (realHeightOfSubmenuContainer > maxHeight){
                    const upper = realHeightOfSubmenuContainer - maxHeight;

                    pos -= upper;
                    maxHeight += upper;
                }

                // if (submenu.querySelector('.container').style.maxHeight === ''){
                //     submenu.querySelector('.container').style.maxHeight = maxHeight+'px';
                // }
            }
        }

        const paddingLeft = parseInt(window.getComputedStyle(submenu).getPropertyValue('padding-left').replace('px', ''));
        const subMenu1Width = subMenu1.offsetWidth;
        const menuWidth = menuButton.querySelector('.menu-list').offsetWidth;

        const listWidth = menuButtonRef.current.querySelector('.menu-list').offsetWidth;
        const buttonLeftPosition = menuButtonRef.current.getBoundingClientRect().left;
        let left, margin;


        const leftButtonPosition = menuButton.getBoundingClientRect().left;
        const leftMenuPosition = submenu.getBoundingClientRect().left;
        const leftMenuListPosition = menuButton.querySelector('.menu-list').getBoundingClientRect().left;

        const leftMenuContainerPosition = menuButton.querySelector('.menu-list .container').getBoundingClientRect().left;
        const widthMenuContainer = menuButton.querySelector('.menu-list .container').offsetWidth;

        const leftSubContainerPosition = submenu.querySelector('.container').getBoundingClientRect().left;
        const widthSubContainer = submenu.querySelector('.container').offsetWidth;

        const leftSub1ContainerPosition = subMenu1.querySelector('.container').getBoundingClientRect().left;
        const rightSub1ContainerPosition = subMenu1.querySelector('.container').getBoundingClientRect().right;
        const widthSub1Container = subMenu1.querySelector('.container').offsetWidth;


        if (leftMenuContainerPosition === 16){
            left = (widthMenuContainer + widthSub1Container) - 50;
            console.debug('A')
        } else {
            if (leftButtonPosition === leftSubContainerPosition){
                if (window.innerWidth > 1200){
                    if (issetScrollableMenu){
                        left = (widthMenuContainer + widthSub1Container) - 22;
                        console.debug('B')
                    } else {
                        if (issetScrollableMenu){
                            left = (widthMenuContainer + widthSub1Container) - 35;
                            console.debug('X1')
                        } else {
                            if (buttonLeftPosition > 37){
                                left = (widthMenuContainer + widthSub1Container) - 35;
                            } else {
                                left = (widthMenuContainer + widthSub1Container) - 42;
                            }
                            console.debug('X2')
                        }
                        console.debug('C')
                    }
                } else  {
                    if (issetScrollableMenu){
                        left = (widthMenuContainer + widthSub1Container) - 29;
                        console.debug('D')
                    } else {
                        left = (widthMenuContainer + widthSub1Container) - 45;
                        console.debug('E')
                    }
                }
            } else {
                if (issetScrollableMenu){
                    if (window.innerWidth < 1200){
                        left = (widthMenuContainer + widthSub1Container) - 30;
                        console.debug('F')
                    } else {
                        left = (widthMenuContainer + widthSub1Container) - 22;
                        console.debug('G')
                    }

                } else {
                    if (issetScrollableMenu){
                        left = (widthMenuContainer + widthSub1Container) - 45;
                        console.debug('X3')
                    } else {
                        if (buttonLeftPosition > 37){
                            left = (widthMenuContainer + widthSub1Container) - 35;
                        } else {
                            left = (widthMenuContainer + widthSub1Container) - 42;
                        }

                        console.debug(buttonLeftPosition)
                        console.debug('X4')
                    }
                    // left = (widthMenuContainer + widthSub1Container) - 35;
                    console.debug('H')
                }
            }
        }

        // const oldPosition = submenu.querySelector('.container').offsetHeight + pos;


        if (issetScrollableMenu){
            submenu.querySelector('.container').style.height = 'calc(100vh - '+(document.querySelector('#root > .header-scroll').offsetHeight + 38 )+'px'+')';
            submenu.querySelector('.arrow').style.height = 'calc(100vh - '+(document.querySelector('#root > .header-scroll').offsetHeight + 38 )+'px'+')';
            submenu.style.height = 'calc(100vh - '+(document.querySelector('#root > .header-scroll').offsetHeight + 38 )+'px'+')';

            setStyleSubMenu({
                submenu: submenu,
                marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
                left: (left+2)+'px',
                top: '68px' });
        } else {
            submenu.querySelector('.container').style.height = 'calc(100vh - '+(document.querySelector('#root > header').offsetHeight + 38 )+'px'+')';
            submenu.querySelector('.arrow').style.height = 'calc(100vh - '+(document.querySelector('#root > header').offsetHeight + 38 )+'px'+')';
            submenu.style.height = 'calc(100vh - '+(document.querySelector('#root > header').offsetHeight + 38 )+'px'+')';

            setStyleSubMenu({
                submenu: submenu,
                marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
                left: (left+2)+'px',
                top: '52px' });
        }




        // if (window.innerHeight - (document.querySelector('#root > header').offsetHeight + 25) < oldPosition){
        //
        //     if (window.innerHeight - (document.querySelector('#root > header').offsetHeight ) > submenu.querySelector('.container').offsetHeight){
        //         pos = ((window.innerHeight - document.querySelector('#root > header').offsetHeight) - submenu.querySelector('.container').offsetHeight) + 20;
        //
        //         setStyleSubMenu({
        //             submenu: submenu,
        //             marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
        //             left: (left+2)+'px',
        //             top: '45px' });
        //     } else {
        //         setStyleSubMenu({
        //             submenu: submenu,
        //             marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
        //             left: (left+2)+'px',
        //             top: '45px' });
        //     }
        // } else {
        //     setStyleSubMenu({
        //         submenu: submenu,
        //         marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
        //         left: (left+2)+'px',
        //         top: '45px' });
        // }


        // setStyleSubMenu({
        //     submenu: submenu,
        //     // marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
        //     marginLeft: menuButton.querySelector('.menu-list').style.marginLeft,
        //     // left: 'calc(50% + '+((widthEl / 2) + (subMenu1Width / 2) + 30)+'px)',
        //     left: left+'px',
        //     // left: (widthEl - paddingLeft - paddingLeft)+subMenu1Width+9+'px',
        //     top: pos+'px' });

        setSubMenu2(submenuId);
    }

    /**
     * Hover-Enter base menu button
     */
    function menuEnter(e){

        const id = e.currentTarget.getAttribute('data-id');
        const leavemenu = e.currentTarget.parentNode.querySelector('.menuitem.active')


        if (leavemenu !== null && menu !== id){
            // baseMenuEl.querySelector('.menuitem[data-id="'+menu+'"]').className = 'menuitem';
            e.currentTarget.parentNode.querySelector('.menuitem.active').className = 'menuitem';
        }

        setMenu(id);

        e.currentTarget.className = 'menuitem active';

        const time = setTimeout(()=>{ setEnableHover(true); }, 200);
        setEnableTimeout(time);
    }

    /**
     * Hover-Leave base menu button
     */
    function menuLeave(){
        const menuRef = menuButtonRef.current;
        const startPositionSubMenu2 = menuRef.getBoundingClientRect().width + 50;

        menuRef.querySelector('.container').scrollTop = 0;

        if (subMenu1 !== null){
            if (menuRef.querySelector('.sm[data-id="'+subMenu1+'"]')){
                menuRef.querySelector('.sm[data-id="'+subMenu1+'"]').removeAttribute('style');
            }

            if (subMenu2 !== null){
                menuRef.querySelector('.sm[data-id="'+subMenu2+'"]').removeAttribute('style');
                // menuRef.querySelector('.sm[data-id="'+subMenu2+'"]').style.left = startPositionSubMenu2+'px';
            }
        }

        if (menu !== null){
            baseMenuEl.querySelector('.menuitem[data-id="'+menu+'"]').className = 'menuitem';
        }

        resetStates();
    }

    /**
     * Load base position for submenu lvl 2 when component is build
     */
    useEffect(()=>{
        marginThreeLvlTree();
    }, []);

    return {menuEnter, menuLeave, hoverSubMenu1, hoverSubMenu2};
}

/**
 * Base menu component with first vertical menu
 */
const Menu = forwardRef(({children, name, data, states, itemText, search, promoted, path}, menuButtonRef) => {

    const {menuEnter, menuLeave, hoverSubMenu1} = useMenu(menuButtonRef, states);
    const arrayData                             = Object.values(data);
    const navigate                              = useNavigate();
    const {t}                                   = useTranslation();
    const containerRef                          = useRef();

    useEffect(() => {
        const menuHeight = document.querySelector('header').offsetHeight;
        const headerScrollHeight = document.querySelector('.header-scroll').offsetHeight;
        const whatIsThat = containerRef.current.parentNode.parentNode.parentNode.parentNode.parentNode.className;
        const promotedSearchHeight = containerRef.current.parentNode.querySelector('.promoted-search').offsetHeight;

        if (whatIsThat.indexOf('header-scroll') !== -1){
            containerRef.current.style.height = 'calc(100vh - '+(headerScrollHeight+70)+'px)';
        } else {
            containerRef.current.style.height = 'calc(100vh - '+(menuHeight+promotedSearchHeight+34)+'px)';
        }

    }, []);

    if (arrayData.length === 0){
        return;
    }

    /**
     * If window is to small (like 1200px width) first menu, is outside the container.
     * If we have prevented this situation, we must calc windows and add margin to scroll menu
     */
    function checkPositionAndCorrect(){
        // NEW
        const buttonLeftPosition = menuButtonRef.current.getBoundingClientRect().left;
        const containerWidth = menuButtonRef.current.querySelector('.menu-list .container').offsetWidth;
        const widthMenuButton = menuButtonRef.current.offsetWidth;
        const least = (containerWidth - widthMenuButton) / 2;

        if (least > buttonLeftPosition){
            menuButtonRef.current.querySelector('.menu-list').style.transform = 'translateX(0)';
            menuButtonRef.current.querySelector('.menu-list').style.left = '0';
            menuButtonRef.current.querySelector('.menu-list').style.paddingLeft = '0';
            menuButtonRef.current.querySelector('.menu-list').style.marginLeft = '0';

            menuButtonRef.current.querySelector('.arrow').style.width = widthMenuButton+'px';
            menuButtonRef.current.querySelector('.arrow').style.position = 'relative';

        } else {
            menuButtonRef.current.querySelector('.arrow').style.width = '100%';
            menuButtonRef.current.querySelector('.arrow').style.position = 'relative';
            menuButtonRef.current.querySelector('.menu-list').removeAttribute('style');
        }
    }



    return (
        <div className="menuitem"
             data-id={name}
             data-search={search}
             onMouseEnter={menuEnter}
             onMouseLeave={menuLeave}
             ref={menuButtonRef}
             onMouseOver={() => { checkPositionAndCorrect() }}>
                <div className={'label'} onClick={() => navigate('/filter/'+path)} >
                    <span>{name}</span><i className="fas fa-angle-right" ></i>
                </div>

                <div className="menu-list" >
                    <div className="arrow" ></div>
                    {search === 'true' ?
                        <div className={'promoted-search'} >
                            <Promoted data={promoted} path={path} />
                            <Search states={states} />
                        </div>
                        : null}
                    <div className="container" ref={containerRef} >
                        <div className={'view-list'} >
                            <Link to={'/filter/'+path} >{t('layout.menu.show-all')}</Link>
                        </div>
                        {states.emptyResultsSearching && states.search ?

                            <div className={'empty'} >Nie znaleziono frazy</div>

                            :

                            <Item items={data}
                                  hover={arrayData[0].hasOwnProperty('children') ? hoverSubMenu1 : null}
                                  itemText={itemText}
                                  clickable={states.clickableFirstMenu}
                                  path={path}/>
                        }
                    </div>
                </div>

            {arrayData.length > 0 && arrayData[0].hasOwnProperty('children') ? children : ''}
        </div>
    );
});

/**
 * Search in menu items
 */
function Search({states}){
    const {t} = useTranslation();

    function startWritting(e){
        states.setSearch(e.currentTarget.value);
    }

    return(
        <>
            <div className="search" >
                <div className="wrapper" >
                    <input onChange={startWritting}
                           type="search"
                           name="search"
                           placeholder={t('layout.menu.tools.search')}
                           autoComplete="off" />
                </div>
            </div>
        </>
    );
}

/**
 * Promoted items on list
 */
function Promoted({data, path}){
    const {t} = useTranslation();

    let promoteData = [];

    Object.values(data).map((value, key)=>{
        if (value.promoted === 1){
            promoteData = [...promoteData, value];
        }
    });

    return(
        <div className="promoted" >
            {/*<div className="title" >{t('layout.menu.tools.promoted')}:</div>*/}
            <Item items={promoteData} path={path} clickable={true} hover={null} itemText="name" />
        </div>
    );
}

/**
 * Sub-menu component
 */
function SubMenu({data, itemsName, itemsText, hover, className, path}){
    return(
        <>
            {Object.values(data).map((subcat, keySubMenu)=>{
                return(
                    <div className={'sm '+className} key={keySubMenu} data-id={subcat.id} >
                        <div className="arrow" ></div>
                        <div className="container" >
                            <Item items={subcat[itemsName]} path={path} clickable={true} itemText={itemsText} hover={hover} />
                        </div>
                    </div>
                );
            })}
        </>
    );
}

/**
 * Submenu item in tree
 */
function Item({items, itemText, hover, clickable, path}){

    function activeArrow(e){
        const menuitem  = e.currentTarget.parentNode.parentNode.parentNode;
        const list      = e.currentTarget.parentNode.parentNode;

        if (menuitem.getAttribute('data-search') !== 'true' || list.hasAttribute('data-id')){
            const arrow = e.currentTarget.parentNode.parentNode.querySelector('.arrow');

            arrow.className = 'arrow active';
        }
    }

    function disActiveArrow(e){
        const arrow = e.currentTarget.parentNode.parentNode.querySelector('.arrow');

        arrow.className = 'arrow';
    }

    function linkOrNot(item){
        if (clickable === true){
            return <Link to={'/products-list/'+path+'/'+item['id']} >{item[itemText]}</Link>;
        }

        return <Link to={'/filter/'+path+'/'+item[itemText]} >{item[itemText]}</Link>;
    }

    return (
        <>
            {Object.values(items).map((item, keyItem)=>{
                return <div className="item"
                            data-id={item['id']}
                            key={item.id+itemText}
                            onMouseOver={hover} >

                    {linkOrNot(item)}
                </div>
            })}
        </>
    );
}

export {Item, SubMenu, Menu, useMenu}
